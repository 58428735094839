import { Box, Stack } from "@mui/material";
import * as React from "react";
import {
  BooleanInput,
  Create,
  CreateProps,
  Loading,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  getRecordFromLocation,
  required,
  useCreate,
  useGetOne,
} from "react-admin";
import { useLocation } from "react-router";
import { CreateOrganizationResult } from "../organizations/organizationsDataProvider";
import { CreateParentSalesforceAccountResult } from "../parentSalesforceAccount/parentSalesforceAccountDataProvider";
import { WonAccountGetOneResult } from "../wonAccounts/wonAccountsDataProvider";
import { US_STATES, timeZoneCodes } from "./constants";
import {
  PMS_LIST,
  createGUID,
  formatPhoneNumber,
  getPMSType,
  parsePhoneNumber,
  parseState,
  useValidateGuid,
  validateEmail,
  validatePhone,
  validateUrl,
  validateZipCode,
} from "./utils";

function useGetWonAccount() {
  const location = useLocation();
  const recordToUse = getRecordFromLocation(location);
  return useGetOne<WonAccountGetOneResult>(
    "wonAccounts",
    {
      id: recordToUse?.salesforceId,
    },
    {
      enabled: !!recordToUse?.salesforceId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

function useCreateParentSalesforceAccount() {
  const [create, state] = useCreate<CreateParentSalesforceAccountResult>();
  return {
    create: (id: string) => {
      return create("parentSalesforceAccount", { data: { id } });
    },
    ...state,
  };
}

function useCreateOrganization() {
  const [create, state] = useCreate<CreateOrganizationResult>();
  return {
    create: (name: string, crmAccountId: string) => {
      return create("organizations", { data: { name, crmAccountId } });
    },
    ...state,
  };
}

function getInitialData(
  account: WonAccountGetOneResult | undefined,
  organization?: CreateOrganizationResult | undefined
) {
  const name = account?.name ?? "";
  return {
    name,
    guid: createGUID(name),
    crmAccountId: account?.id ?? "",
    organizationId: organization?.id ?? account?.parent?.organizationId ?? "",
    practiceManagementSystemType:
      getPMSType(account?.practiceManagementSystem) ?? "",
    address1: account?.billingStreet ?? "",
    city: account?.billingCity ?? "",
    state: parseState(account?.billingState) ?? "",
    zip: account?.billingPostalCode ?? "",
    email: account?.email ?? "",
    phone: parsePhoneNumber(account?.phone) ?? "",
    website: account?.website ?? "",
    contactFirstName: account?.primaryContact?.firstName ?? "",
    contactLastName: account?.primaryContact?.lastName ?? "",
    skipCreateSyncCredentials: false,
  };
}

export default function ClinicCreate(props: CreateProps): JSX.Element {
  const {
    data: account,
    isLoading: accountLoading,
    isFetching: accountFetching,
  } = useGetWonAccount();
  const {
    create: createParent,
    data: createdParent,
    isLoading: createParentLoading,
    error: createParentError,
  } = useCreateParentSalesforceAccount();
  const parent = createdParent ?? account?.parent;
  const {
    create: createOrganization,
    data: createdOrganization,
    isLoading: createOrganizationLoading,
    error: createOrganizationError,
  } = useCreateOrganization();
  const validateGuid = useValidateGuid();

  React.useEffect(() => {
    if (
      !!account &&
      account.parent === null &&
      !createParentLoading &&
      !createParentError &&
      !createdParent
    ) {
      createParent(account.id);
    }

    if (
      !accountLoading &&
      !!account &&
      parent &&
      parent.organizationId === null &&
      !createOrganizationLoading &&
      !createOrganizationError &&
      !createdOrganization
    ) {
      createOrganization(parent.name, parent.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    accountLoading,
    createdParent,
    createdOrganization,
    createOrganizationLoading,
    createParentLoading,
  ]);

  if (
    accountLoading ||
    createParentLoading ||
    createOrganizationLoading ||
    accountFetching
  ) {
    return <Loading />;
  }

  const initialData = getInitialData(account, createdOrganization);

  return (
    <Create record={initialData} redirect="show" {...props}>
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
      >
        <Stack spacing={1} width="100%" maxWidth={600}>
          <TextInput
            source="name"
            validate={required()}
            helperText="This is the name displayed to both customers and patients"
          />
          <TextInput
            source="guid"
            validate={[required(), validateGuid]}
            helperText="If the GUID seems too long, this is the best moment to change it. Changing it after the clinic starts using Engagement will have consequences."
          />
          <ReferenceInput source="organizationId" reference="organizations">
            <SelectInput
              optionValue="id"
              optionText="name"
              validate={required()}
              disabled
            />
          </ReferenceInput>
          <TextInput
            source="country"
            defaultValue="US"
            validate={required()}
            disabled
          />
          <TextInput source="address1" />
          <TextInput source="city" />
          <Stack direction="row" spacing={2}>
            <SelectInput
              choices={[...US_STATES]}
              source="state"
              optionValue="id"
              optionText="id"
              margin="none"
              fullWidth
            />
            <TextInput source="zip" validate={validateZipCode} />
          </Stack>
          <TextInput source="email" validate={validateEmail} />
          <TextInput
            source="phone"
            validate={validatePhone}
            parse={parsePhoneNumber}
            format={formatPhoneNumber}
          />
          <TextInput source="website" validate={validateUrl} />
          <SelectInput
            choices={[...timeZoneCodes]}
            source="timezone"
            optionValue="id"
            optionText="id"
            validate={required()}
          />
          <SelectInput
            choices={[...PMS_LIST]}
            source="practiceManagementSystemType"
            optionValue="value"
            optionText="label"
            validate={required()}
          />
          <TextInput source="crmAccountId" validate={required()} disabled />
          <BooleanInput
            label="Doesn’t require an installation key (using another clinic’s connection)"
            source="skipCreateSyncCredentials"
          />
        </Stack>
        <Box sx={{ color: "grey.600" }}>*Required fields</Box>
      </SimpleForm>
    </Create>
  );
}
