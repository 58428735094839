import * as React from "react";
import {
  DateField,
  DeleteButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
import AsideContainer from "../components/AsideContainer";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";
import RecordTitle from "../components/RecordTitle";

function DiPracticeActions() {
  return (
    <TopToolbar>
      <DeleteButton label="Remove Grant" />
    </TopToolbar>
  );
}

function DiPracticeGrantAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="grantId" />
        <DateField source="createdAt" showTime />
        <LinkReferenceField source="createdBy.id" reference="users" link="show">
          <TextField source="createdBy.username" emptyText="–" />
        </LinkReferenceField>
      </SimpleShowLayout>
    </AsideContainer>
  );
}

export default function DiPracticeGrantShow(props: ShowProps): JSX.Element {
  return (
    <Show
      {...props}
      title={<RecordTitle source="name" />}
      actions={<DiPracticeActions />}
      aside={<DiPracticeGrantAside />}
    >
      <SimpleShowLayout spacing={2}>
        <TextField source="name" />
        <LinkReferenceField
          source="practice.id"
          reference="diPractices"
          link="show"
        >
          <TextField source="practice.name" emptyText="–" />
        </LinkReferenceField>
        <LinkReferenceField source="grantId" reference="diGrants" link="show">
          <TextField source="name" emptyText="–" />
        </LinkReferenceField>
      </SimpleShowLayout>
    </Show>
  );
}
