import * as React from "react";
import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

export default function OrganizationCreate(props: CreateProps) {
  return (
    <Create redirect="show" {...props}>
      <SimpleForm mode="onChange" reValidateMode="onChange">
        <TextInput source="name" validate={required()} />
        <TextInput source="crmAccountId" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
