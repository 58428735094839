import { Box, Stack } from "@mui/material";
import * as React from "react";
import {
  BooleanInput,
  Edit,
  EditProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import RecordTitle from "../components/RecordTitle";
import { US_STATES, timeZoneCodes } from "./constants";
import { ClinicCustomerType, PMSType } from "./type";
import {
  PMS_LIST,
  createGUID,
  formatPhoneNumber,
  parsePhoneNumber,
  useValidateGuid,
  validateEmail,
  validatePhone,
  validateUrl,
  validateZipCode,
} from "./utils";

function GuidInput() {
  const validateGuid = useValidateGuid();
  const record = useRecordContext();
  const original = React.useRef({
    guid: record.guid,
    guidAlias: record.guidAlias,
  });
  const { guid: originalGuid, guidAlias: originalGuidAlias } = original.current;
  const form = useFormContext();
  // For now we'll only show the GUID field when it's currently null.
  if (originalGuid != null) {
    return null;
  }
  return (
    <>
      <TextInput
        source="guid"
        defaultValue={createGUID(record.name)}
        validate={[required(), validateGuid]}
        helperText={
          "If the GUID seems too long, this is the best moment to change it. Changing it after the clinic starts using Engagement will have consequences."
          // originalGuidAlias == null
          //   ? "You can change this one time without disabling links."
          //   : `Changing this again will disable links for '${originalGuidAlias}'.`
        }
        onBlur={() => {
          const { guid, guidAlias } = form.getValues();
          if (guid === originalGuid) {
            if (guidAlias !== originalGuidAlias) {
              form.setValue("guidAlias", originalGuidAlias);
            }
          } else {
            form.setValue("guidAlias", originalGuid);
          }
        }}
      />
    </>
  );
}

export function ConvertToCustomerInput() {
  const record = useRecordContext();
  if (record.customerType === ClinicCustomerType.CUSTOMER) {
    return null;
  }
  return (
    <BooleanInput
      source="isCustomer"
      label="Convert to Customer"
      defaultValue
    />
  );
}

export function PMSTypeSelectInput() {
  const record = useRecordContext();
  if (record.practiceManagementSystemType !== PMSType.Unknown) {
    return null;
  }
  return (
    <SelectInput
      choices={[...PMS_LIST]}
      source="practiceManagementSystemType"
      optionValue="value"
      optionText="label"
      validate={required()}
    />
  );
}

export default function ClinicEdit(props: EditProps): JSX.Element {
  return (
    <Edit {...props} redirect="show" title={<RecordTitle source="name" />}>
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
      >
        <Stack spacing={1} width="100%" maxWidth={600}>
          <ConvertToCustomerInput />
          <TextInput source="name" validate={required()} />
          <GuidInput />
          <TextInput
            source="country"
            defaultValue="US"
            disabled
            validate={required()}
          />
          <TextInput source="address1" />
          <TextInput source="city" />
          <Stack direction="row" spacing={2}>
            <SelectInput
              choices={[...US_STATES]}
              source="state"
              optionValue="id"
              optionText="id"
              margin="none"
              fullWidth
            />
            <TextInput source="zip" validate={validateZipCode} />
          </Stack>
          <TextInput source="email" validate={validateEmail} />
          <TextInput
            source="phone"
            validate={validatePhone}
            parse={parsePhoneNumber}
            format={formatPhoneNumber}
          />
          <TextInput source="website" validate={validateUrl} />
          <SelectInput
            choices={[...timeZoneCodes]}
            source="timezone"
            optionValue="id"
            optionText="id"
            validate={required()}
          />
          <PMSTypeSelectInput />
        </Stack>
        <Box sx={{ color: "grey.600" }}>*Required fields</Box>
      </SimpleForm>
    </Edit>
  );
}
