import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import appHealthDataProvider from "../appHealth/appHealthDataProvider";
import { clinicsDataProvider } from "../clinics";
import { diGrantsDataProvider } from "../diGrants";
import { diPracticeGrantsDataProvider } from "../diPracticeGrants";
import { diPracticesDataProvider } from "../diPractices";
import guidDataProvider from "../guid/guidDataProvider";
import { lmOfficesDataProvider } from "../lmOffices";
import { lmPracticesDataProvider } from "../lmPractices";
import { organizationsDataProvider } from "../organizations";
import parentSalesforceAccountDataProvider from "../parentSalesforceAccount/parentSalesforceAccountDataProvider";
import { usersDataProvider } from "../users";
import wonAccountsDataProvider from "../wonAccounts/wonAccountsDataProvider";

const dataProviders: Record<string, DataProvider> = {
  appHealth: appHealthDataProvider,
  clinics: clinicsDataProvider,
  diGrants: diGrantsDataProvider,
  diPracticeGrants: diPracticeGrantsDataProvider,
  diPractices: diPracticesDataProvider,
  lmOffices: lmOfficesDataProvider,
  lmPractices: lmPracticesDataProvider,
  organizations: organizationsDataProvider,
  users: usersDataProvider,
  wonAccounts: wonAccountsDataProvider,
  guid: guidDataProvider,
  parentSalesforceAccount: parentSalesforceAccountDataProvider,
};

function getDataProvider(resource: string): DataProvider {
  const dataProvider = dataProviders[resource];
  if (dataProvider == null) {
    throw new Error(
      `Data provider could not be found for resource: "${resource}"`
    );
  }
  return dataProvider;
}

const uberAdminDataProvider: DataProvider = {
  async getList<RecordType extends RaRecord = any>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    return getDataProvider(resource).getList(resource, params);
  },

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    return getDataProvider(resource).getOne(resource, params);
  },

  async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    return getDataProvider(resource).getMany(resource, params);
  },

  async getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    return getDataProvider(resource).getManyReference(resource, params);
  },

  async update<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    return getDataProvider(resource).update(resource, params);
  },

  async updateMany(
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> {
    return getDataProvider(resource).updateMany(resource, params);
  },

  async create<RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<RecordType>> {
    return getDataProvider(resource).create(resource, params);
  },

  async delete<RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    return getDataProvider(resource).delete(resource, params);
  },

  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    return getDataProvider(resource).deleteMany(resource, params);
  },
};

export default uberAdminDataProvider;
