import * as React from "react";
import {
  AutocompleteInput,
  Create,
  CreateProps,
  getRecordFromLocation,
  Identifier,
  RaRecord,
  ReferenceInput,
  SimpleForm,
  useCreatePath,
} from "react-admin";
import { useLocation } from "react-router";

export default function DiPracticeGrantCreate(props: CreateProps) {
  const createPath = useCreatePath();
  const location = useLocation();
  const recordToUse = getRecordFromLocation(location);

  return (
    <Create
      {...props}
      redirect={(
        resource?: string,
        id?: Identifier,
        data?: Partial<RaRecord>
      ) => {
        let pathname: string;
        if (data?.practiceId) {
          pathname = createPath({
            resource: "diPractices",
            id: data.practiceId,
            type: "show",
          });
        } else {
          pathname = createPath({ resource: "diGrants", type: "list" });
        }
        return { pathname };
      }}
    >
      <SimpleForm>
        <ReferenceInput source="practiceId" reference="diPractices">
          <AutocompleteInput
            label="DI Practice"
            optionText="name"
            disabled={recordToUse.practiceId != null}
            sx={{ width: "50%" }}
          />
        </ReferenceInput>
        <ReferenceInput source="grantId" reference="diGrants">
          <AutocompleteInput
            label="Grant"
            optionText="name"
            disabled={recordToUse.grantId != null}
            sx={{ width: "50%" }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
