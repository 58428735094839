import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import * as React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useCreatePath,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Link } from "react-router-dom";
import AsideContainer from "../components/AsideContainer";
import BooleanColorField from "../components/BooleanColorField";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";
import RecordTitle from "../components/RecordTitle";
import ReferenceListField from "../components/ReferenceListField";
import TabLabel from "../components/TabLabel";

function DiPracticeAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <IdField source="customerNumber" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <DateField source="firstSyncedAt" showTime emptyText="–" />
        <DateField source="lastSyncedAt" showTime emptyText="–" />
        <BooleanColorField source="isActive" />
      </SimpleShowLayout>
    </AsideContainer>
  );
}

function RemovePracticeGrantButton() {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const refresh = useRefresh();
  return (
    <DeleteWithConfirmButton
      label="Remove"
      resource="diPracticeGrants"
      record={record}
      mutationMode="pessimistic"
      translateOptions={{ id: record.grantId, name: record.name }}
      redirect={() => {
        const pathname = createPath({
          resource: "diPractices",
          type: "show",
          id: record.practiceId,
        });
        refresh();
        return { pathname };
      }}
    />
  );
}

export default function DiPracticeShow(props: ShowProps): JSX.Element {
  const createPath = useCreatePath();
  return (
    <Show
      {...props}
      title={<RecordTitle source="name" />}
      aside={<DiPracticeAside />}
    >
      <TabbedShowLayout spacing={2}>
        <Tab label="Summary">
          <TextField source="name" />
          <TextField source="phone" emptyText="–" />
          <TextField source="email" emptyText="–" />
          <TextField source="pmsName" emptyText="–" />
          <LinkReferenceField
            source="clinic.id"
            reference="clinics"
            link="show"
          >
            <TextField source="clinic.name" emptyText="–" />
          </LinkReferenceField>
        </Tab>
        <Tab
          label={
            <TabLabel label="Feature Flags" countSource="featureFlags.length" />
          }
          path="featureFlags"
        >
          <ArrayField source="featureFlags" label={false}>
            <Datagrid
              resource="diPracticeGrants"
              bulkActionButtons={false}
              rowClick="show"
            >
              <IdField source="grantId" />
              <TextField source="name" />
              <RemovePracticeGrantButton />
            </Datagrid>
          </ArrayField>
          <FunctionField
            render={(record: any) => (
              <Button
                component={Link}
                to={createPath({
                  resource: "diPracticeGrants",
                  type: "create",
                })}
                state={{ record: { practiceId: record.id } }}
                size="small"
                startIcon={<Add />}
              >
                Add Feature Flag
              </Button>
            )}
          />
        </Tab>
        <Tab
          label={
            <TabLabel label="Users" countSource="practiceMemberships.length" />
          }
          path="users"
        >
          <ReferenceListField source="users" reference="users" label={false}>
            <TextField source="username" />
            <BooleanColorField source="isActive" />
            <BooleanColorField source="isPracticeAdmin" label="Admin" />
          </ReferenceListField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
