import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import {
  DateField,
  Labeled,
  SelectField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import AsideContainer from "../components/AsideContainer";
import ExternalLinkReferenceField from "../components/ExternalLinkReferenceField";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";
import RecordTitle from "../components/RecordTitle";
import { clinicCustomerTypeOptions } from "./constants";
import { PMS_LIST } from "./utils";

function ClinicAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <IdField source="customerNumber" />
        <IdField source="guid" />
        <IdField source="guidAlias" />
        <DateField source="createdAt" showTime />
        <LinkReferenceField source="createdBy.id" reference="users" link="show">
          <TextField source="createdBy.username" emptyText="–" />
        </LinkReferenceField>
        <DateField source="updatedAt" showTime />
        <LinkReferenceField source="updatedBy.id" reference="users" link="show">
          <TextField source="updatedBy.username" emptyText="–" />
        </LinkReferenceField>
      </SimpleShowLayout>
    </AsideContainer>
  );
}

function AdminLinkContent() {
  return (
    <Typography variant="caption">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <span>Admin</span> <ExitToAppIcon fontSize="inherit" />
      </Stack>
    </Typography>
  );
}

export default function ClinicShow(props: ShowProps): JSX.Element {
  return (
    <Show
      {...props}
      title={<RecordTitle source="name" />}
      aside={<ClinicAside />}
    >
      <SimpleShowLayout spacing={2}>
        <TextField source="name" />
        <LinkReferenceField
          source="organization.id"
          reference="organizations"
          link="show"
        >
          <TextField source="organization.name" emptyText="–" />
        </LinkReferenceField>
        <LinkReferenceField
          source="diPractice.id"
          reference="diPractices"
          link="show"
        >
          <AdminLinkContent />
        </LinkReferenceField>
        <Labeled source="mdPractice">
          <Stack spacing={0.5}>
            <ExternalLinkReferenceField
              reference="mdPractices"
              source="mdPracticeId"
            />
            <ExternalLinkReferenceField
              reference="mdDashboardPractices"
              source="guid"
            />
          </Stack>
        </Labeled>
        <Labeled source="lmOffice">
          <Stack spacing={0.5}>
            <LinkReferenceField
              source="lmOffice.id"
              reference="lmOffices"
              link="show"
            >
              <AdminLinkContent />
            </LinkReferenceField>
            <ExternalLinkReferenceField
              reference="lmOffices"
              source="lmOffice.id"
            />
          </Stack>
        </Labeled>
        <Labeled source="crmAccountId">
          <Box>
            <IdField source="crmAccountId" />
            <ExternalLinkReferenceField
              reference="salesforceAccounts"
              source="crmAccountId"
            />
          </Box>
        </Labeled>
        <Divider />
        <Typography variant="subtitle2">Info</Typography>
        <SelectField
          choices={clinicCustomerTypeOptions}
          source="customerType"
        />
        <SelectField
          choices={[...PMS_LIST]}
          source="practiceManagementSystemType"
          optionValue="value"
          optionText="label"
        />
        <TextField source="phone" emptyText="–" />
        <TextField source="email" emptyText="–" />
        <TextField source="website" emptyText="–" />
        <Divider />
        <Typography variant="subtitle2">Address</Typography>
        <TextField source="address1" emptyText="–" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Labeled source="city">
              <TextField source="city" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="state">
              <TextField source="state" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="zip">
              <TextField source="zip" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="country">
              <TextField source="country" emptyText="–" />
            </Labeled>
          </Grid>
        </Grid>
        <TextField source="timezone" emptyText="–" />
      </SimpleShowLayout>
    </Show>
  );
}
