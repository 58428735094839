import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import {
  useGetResourceLabel,
  useRecordContext,
  useResourceContext,
} from "react-admin";

interface RecordTitleProps {
  resource?: string;
  source: string | ((record: any) => React.ReactNode);
}

export default function RecordTitle({ source, ...props }: RecordTitleProps) {
  const resource = useResourceContext(props);
  const getResourceLabel = useGetResourceLabel();
  const record = useRecordContext();
  let title = "";
  if (record) {
    title = typeof source === "function" ? source(record) : get(record, source);
  }
  return (
    <>
      <Typography variant="overline" component="span">
        {getResourceLabel(resource)} <NavigateNextIcon fontSize="inherit" />
      </Typography>{" "}
      {title}
    </>
  );
}

RecordTitle.defaultProps = {
  resource: undefined,
};
