import * as React from "react";
import {
  Edit,
  EditProps,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import RecordTitle from "../components/RecordTitle";

export default function OrganizationEdit(props: EditProps) {
  return (
    <Edit redirect="show" {...props} title={<RecordTitle source="name" />}>
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  );
}
