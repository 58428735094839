import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import {
  gql,
  normalizeGuid,
  postGraphql,
  postGraphqlMutation,
} from "../dataProvider/utils";
import { ClinicCustomerType } from "./type";
import { parsePhoneNumber, parseUrl } from "./utils";

const clinicsDataProvider: DataProvider = {
  ...baseDataProvider,
  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const { pagination, filter } = params;
    const response = await postGraphql(
      gql`
        query UAClinicsGetList(
          $search: String
          $organizationId: ID
          $customerTypeIn: [ClinicCustomerType!]
          $page: Int
          $perPage: Int
        ) {
          clinics(
            search: $search
            organizationId: $organizationId
            customerTypeIn: $customerTypeIn
            page: $page
            perPage: $perPage
          ) {
            pageInfo {
              totalCount
            }
            nodes {
              id
              name
              customerNumber
              organization {
                id
                name
              }
              diPracticeId
              mdPracticeId
              lmOfficeId
              crmAccountId
              createdAt
            }
          }
        }
      `,
      {
        page: pagination.page,
        perPage: pagination.perPage,
        customerTypeIn: [ClinicCustomerType.CUSTOMER, ClinicCustomerType.DEMO],
        search: filter.q || filter.search,
        organizationId: filter.organization?.id,
      }
    );
    return {
      data: response.json.data.clinics.nodes,
      total: response.json.data.clinics.pageInfo.totalCount,
    };
  },

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const response = await postGraphql(
      gql`
        query UAClinicGetOne($id: ID!) {
          clinic(id: $id) {
            id
            customerNumber
            guid
            guidAlias
            organization {
              id
              name
            }
            diPracticeId
            diPractice {
              id
              name
            }
            lmOfficeId
            lmOffice {
              id
              display
            }
            mdPracticeId
            crmAccountId
            name
            practiceManagementSystemType
            customerType
            address1
            address2
            city
            state
            zip
            country
            timezone
            phone
            email
            website
            contactFirstName
            contactLastName
            createdAt
            createdBy {
              id
              username
            }
            updatedAt
            updatedBy {
              id
              username
            }
          }
        }
      `,
      { id: params.id }
    );
    const { clinic } = response.json.data;
    normalizeGuid(clinic, "createdBy.id");
    normalizeGuid(clinic, "updatedBy.id");
    return { data: clinic };
  },

  async create(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<any>> {
    const { data } = params;

    const response = await postGraphqlMutation(
      gql`
        mutation UAClinicCreate($input: CreateClinicInput!) {
          createClinic(input: $input) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              name
            }
          }
        }
      `,
      {
        input: {
          name: data.name,
          guid: data.guid,
          crmAccountId: data.crmAccountId,
          practiceManagementSystemType: data.practiceManagementSystemType,
          organizationId: data.organizationId,
          address1: data.address1,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
          timezone: data.timezone,
          email: data.email,
          phone: parsePhoneNumber(data.phone),
          website: parseUrl(data.website),
          contactFirstName: data.contactFirstName,
          contactLastName: data.contactLastName,
          skipCreateSyncCredentials: data.skipCreateSyncCredentials,
        },
      },
      "createClinic"
    );
    return {
      data: {
        ...data,
        ...response.json.data.createClinic.payload,
      },
    };
  },

  async update(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<any>> {
    const { id, data } = params;
    const response = await postGraphqlMutation(
      gql`
        mutation UAClinicUpdate($id: ID!, $input: UpdateClinicInput!) {
          updateClinic(id: $id, input: $input) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              name
            }
          }
        }
      `,
      {
        id,
        input: {
          name: data.name,
          address1: data.address1,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
          timezone: data.timezone,
          email: data.email,
          phone: parsePhoneNumber(data.phone),
          website: parseUrl(data.website),
          ...(data.guid ? { guid: data.guid } : undefined),
          ...(data.isCustomer
            ? { customerType: ClinicCustomerType.CUSTOMER }
            : undefined),
          ...(data.practiceManagementSystemType
            ? {
                practiceManagementSystemType: data.practiceManagementSystemType,
              }
            : undefined),
        },
      },
      "updateClinic"
    );
    return {
      data: { ...data, ...response.json.data.updateClinic.payload },
    };
  },
};

export default clinicsDataProvider;
