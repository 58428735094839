import { LocalHospital as HealthIcon } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import StoreIcon from "@mui/icons-material/Store";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import React from "react";
import {
  Admin,
  CustomRoutes,
  Layout,
  LayoutProps,
  Menu,
  MenuProps,
  Resource,
} from "react-admin";
import { Navigate } from "react-router";
import { BrowserRouter, Route } from "react-router-dom";
import { AppHealthList } from "./appHealth";
import { AuthorizedPage, LoginPage, authProvider } from "./auth";
import { ClinicCreate, ClinicEdit, ClinicList, ClinicShow } from "./clinics";
import CollapsibleMenuItem from "./components/CollapsibleMenuItem";
import ResourceMenuItem from "./components/ResourceMenuItem";
import uberAdminDataProvider from "./dataProvider/uberAdminDataProvider";
import { DiGrantList, DiGrantShow } from "./diGrants";
import { DiPracticeGrantCreate, DiPracticeGrantShow } from "./diPracticeGrants";
import { DiPracticeList, DiPracticeShow } from "./diPractices";
import { LmOfficeShow } from "./lmOffices";
import { LmPracticeShow } from "./lmPractices";
import i18nProvider from "./locales";
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationList,
  OrganizationShow,
} from "./organizations";
import { organizationListFilterDefaultValues } from "./organizations/OrganizationList";
import { PublicRoutes } from "./routes";
import theme from "./theme";
import { UserList, UserShow } from "./users";
import WonAccountsList from "./wonAccounts/WonAccountsList";

function CustomMenu(props: MenuProps) {
  return (
    <Menu {...props}>
      <ResourceMenuItem name="clinics" />
      <ResourceMenuItem name="wonAccounts" />
      <ResourceMenuItem
        name="organizations"
        filterDefaultValues={organizationListFilterDefaultValues}
      />
      <CollapsibleMenuItem primaryText="Advanced">
        <ResourceMenuItem name="diPractices" />
        <ResourceMenuItem name="users" />
        <ResourceMenuItem name="diGrants" />
        <ResourceMenuItem name="appHealth" />
      </CollapsibleMenuItem>
    </Menu>
  );
}

function CustomLayout(props: LayoutProps) {
  return <Layout {...props} menu={CustomMenu} />;
}

export default function App() {
  return (
    <BrowserRouter>
      <Admin
        dataProvider={uberAdminDataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        theme={theme}
        loginPage={LoginPage}
        layout={CustomLayout}
      >
        <CustomRoutes>
          <Route path={PublicRoutes.AUTHORIZED} element={<AuthorizedPage />} />
          <Route path="won-accounts" element={<Navigate to="/wonAccounts" />} />
        </CustomRoutes>
        <Resource
          name="clinics"
          create={ClinicCreate}
          list={ClinicList}
          show={ClinicShow}
          edit={ClinicEdit}
          icon={StoreIcon}
        />
        <Resource
          name="wonAccounts"
          list={WonAccountsList}
          create={ClinicCreate}
          icon={TrendingUpIcon}
        />
        <Resource
          name="organizations"
          create={OrganizationCreate}
          list={OrganizationList}
          show={OrganizationShow}
          edit={OrganizationEdit}
          icon={BusinessIcon}
        />
        <Resource
          name="diPractices"
          show={DiPracticeShow}
          list={DiPracticeList}
        />
        <Resource name="users" show={UserShow} list={UserList} />
        <Resource name="diGrants" show={DiGrantShow} list={DiGrantList} />
        <Resource
          name="appHealth"
          list={AppHealthList}
          options={{ label: "App Health" }}
          icon={HealthIcon}
        />
        <Resource name="lmOffices" show={LmOfficeShow} />
        <Resource name="lmPractices" show={LmPracticeShow} />
        <Resource
          name="diPracticeGrants"
          create={DiPracticeGrantCreate}
          show={DiPracticeGrantShow}
        />
      </Admin>
    </BrowserRouter>
  );
}
