import polyglotI18nProvider from "ra-i18n-polyglot";
import raEnglishMessages from "ra-language-english";

function hasOneRelationMessages(
  field: string,
  name: string
): Record<string, string> {
  return {
    [field]: name,
    [`${field}.id`]: name,
    [`${field}Id`]: `${name} ID`,
  };
}

const commonFieldNames = {
  id: "ID",
  isActive: "Active",
  createdAt: "Created At",
  ...hasOneRelationMessages("createdBy", "Created By"),
  customerNumber: "Customer #",
  updatedAt: "Updated At",
  ...hasOneRelationMessages("updatedBy", "Updated By"),
  featureFlags: "Feature Flags",
};

const messages = {
  en: {
    ...raEnglishMessages,
    message: {
      ...(raEnglishMessages.message as any),
      external_link_references: {
        mdPractices: "Admin",
        mdDashboardPractices: "Dashboard",
        lmOffices: "Dashboard",
        lmPractices: "Dashboard",
        salesforceAccounts: "View in Salesforce",
      },
      reference_collection_field: {
        showing: "Showing %{showingCount} of %{totalCount}",
        view_all: "View All",
      },
    },
    notification: {
      ...(raEnglishMessages.notification as any),
      copied: "%{content} copied.",
    },
    resources: {
      clinics: {
        name: "Clinic |||| Clinics",
        fields: {
          ...commonFieldNames,
          ...hasOneRelationMessages("crmAccount", "Salesforce Account"),
          ...hasOneRelationMessages("diPractice", "Analytics Clinic"),
          ...hasOneRelationMessages("lmOffice", "LocalMed Clinic"),
          ...hasOneRelationMessages("mdPractice", "Engagement Clinic"),
          ...hasOneRelationMessages("organization", "Organization"),
          address1: "Street",
          city: "City",
          state: "State",
          zip: "Zip",
          country: "Country",
          timezone: "Timezone",
          email: "Email",
          phone: "Phone Number",
          website: "Office Website",
          practiceManagementSystemType: "PMS",
          customerType: "Customer Type",
          guid: "Engagement GUID",
          guidAlias: "Engagement GUID Alias",
        },
      },
      diGrants: {
        name: "Grant |||| Grants",
        fields: {
          ...commonFieldNames,
        },
      },
      diPractices: {
        name: "Analytics Clinic |||| Analytics Clinics",
        fields: {
          ...commonFieldNames,
          phone: "Phone Number",
          firstSyncedAt: "First Synced At",
          lastSyncedAt: "Last Synced At",
          featureFlags: "Feature Flags",
          pmsName: "PMS Name",
          ...hasOneRelationMessages("clinic", "Clinic"),
        },
      },
      diPracticeGrants: {
        name: "Analytics Clinic Grant |||| Analytics Clinics Grants",
        fields: {
          ...commonFieldNames,
          grantId: "Grant ID",
          ...hasOneRelationMessages("practice", "Analytics Clinic"),
        },
      },
      lmOffices: {
        name: "LocalMed Clinic |||| LocalMed Clinics",
        fields: {
          ...commonFieldNames,
          messagingName: "Messaging Name",
          address1: "Address Line 1",
          address2: "Address Line 2",
          stateCode: "State",
          postalCode: "Postal Code",
          timeZoneCode: "Time Zone",
          announcementEnabled: "Announcement",
          appointmentRemindersEnabled: "Appointment Reminders",
          appointmentReschedulingEnabled: "Appointment Rescheduling",
          insuranceCoverageEnabled: "Insurance Coverage",
          onlineSchedulingEnabled: "Online Scheduling",
          patientScreenerEnabled: "Patient Screener",
          ...hasOneRelationMessages("clinic", "Clinic"),
          ...hasOneRelationMessages("lmPractice", "LocalMed Practice"),
        },
      },
      lmPractices: {
        name: "LocalMed Practice |||| LocalMed Practices",
        fields: {
          ...commonFieldNames,
          intakeFormUrl: "Intake Form Url",
          onlineSchedulingEnabled: "Online Scheduling",
          whiteLabeledNotificationsEnabled: "White Labeled Notifications",
          ...hasOneRelationMessages("organization", "Organization"),
        },
      },
      organizations: {
        name: "Organization |||| Organizations",
        fields: {
          ...commonFieldNames,
          ...hasOneRelationMessages("crmAccount", "Salesforce Account"),
          ...hasOneRelationMessages("lmPractice", "LocalMed Practice"),
          ...hasOneRelationMessages("mdDentalGroup", "Engagement Organization"),
          "clinics.nodes": "Clinics",
        },
      },
      wonAccounts: {
        name: "Won Account |||| Won Accounts",
        fields: {
          id: "Salesforce Account ID",
          name: "Account",
          isSnapshot: "Snapshot",
          hasClinic: "Has Clinic",
          practiceManagementSystem: "PMS",
          hasActiveProducts: "Active Products",
          "parent.name": "Salesforce Parent Account",
        },
      },
      users: {
        name: "User |||| Users",
        fields: {
          ...commonFieldNames,
          givenName: "Given Name",
          familyName: "Family Name",
          phoneNumber: "Phone Number",
        },
      },
    },
  },
};

const i18nProvider = polyglotI18nProvider(() => messages.en, "en");

export default i18nProvider;
